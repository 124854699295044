/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'variables';
@import 'utilities';
@import 'bootstrap/scss/mixins.scss';
@import "react-datepicker/dist/react-datepicker.css";
@import "react-phone-input-2/lib/style.css";
@import 'react-quill/dist/quill.snow.css';
@import "flag-icons/css/flag-icons.min.css";

// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/vertical';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/layouts';

// Components
@import 'custom/components/waves';
@import 'custom/components/avatar';
@import 'custom/components/accordion';
@import 'custom/components/helper';
@import 'custom/components/preloader';
@import 'custom/components/forms';
@import 'custom/components/widgets';
@import 'custom/components/demos';
@import 'custom/components/print';
@import 'custom/components/stackingModal';
@import "custom/components/switchInput";
@import 'custom/components/empty';
@import 'custom/components/defaultWalletModal';
@import "custom/components/input";
@import "custom/components/notificationSetting";

// Plugins
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/calendar';
@import 'custom/plugins/dragula';
@import 'custom/plugins/session-timeout';
@import 'custom/plugins/range-slider';
@import 'custom/plugins/sweatalert2';
@import 'custom/plugins/rating';
@import 'custom/plugins/toastr';
@import 'custom/plugins/parsley';
@import 'custom/plugins/select2';

@import 'custom/plugins/switch';
@import 'custom/plugins/colorpicker';
@import 'custom/plugins/timepicker';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/form-editors';
@import 'custom/plugins/form-upload';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/datatable';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/table-editable';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/echarts';
@import 'custom/plugins/flot';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/google-map';
@import 'custom/plugins/vector-maps';
@import 'custom/plugins/leaflet-maps';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/ecommerce';
@import 'custom/pages/email';
@import 'custom/pages/file-manager';
@import 'custom/pages/metrics';
@import 'custom/pages/notifications';
@import 'custom/pages/chat';
@import 'custom/pages/contacts';
@import 'custom/pages/crypto';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/extras-pages';
@import "custom/pages/investment";
@import "custom/components/datepicker";

// html {
//   overflow-y: overlay;
// }

iframe {
  display: none;
}

.react-toast-notifications__container {
  z-index: 1000000 !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

// th.sortable {
//   display: flex;
//   justify-content: center;
//   gap: 10px;
//   flex-wrap: wrap;
// }

.table-head {
  background-color: #f8f9fa;

  .head-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
      font-size: 13px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
    &.centered_title {
      p {
        margin: 0 auto;
      }
    }
  }
}

.btn {
  &.disabled,
  &:disabled {
    background-color: gray;
    border-color: gray;
  }
}

.table_mobile_select {
  select {
    max-width: 100%;
  }
}

.col-mobile {
  @media screen and (max-width: 770px){
    flex-direction: column;
  }
}


.picker-date {
  height: 38px !important;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: 180px;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }

  @media screen and (max-width: 768px){
    width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  height: 38px !important;
  @media screen and (max-width: 1240px) {
    width: 100%;
  }
}

.react-datepicker-popper {
  z-index: 10;
}

:root {
  --modal-z-index: 10000 !important;
  --w3m-modal-z-index: 10000 !important;
}


.react-flow__panel.react-flow__attribution {
  display: none !important;
}

.react-flow__handle {
  min-width: 1px;
  min-height: 1px;
  width: 1px;
  height: 1px;
  border: none !important;
  background: transparent !important;
}

.react-datepicker__year-text {
  width: 100% !important;
}

.ql-container {
  min-height: 220px !important;
  border-radius: 0 0 8px 8px;
}

.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
  padding: 10px 8px;
}

.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 18px !important;
  height: 18px !important;
}

.card-body.investments-body {
  @media screen and (max-width: 768px) {
    padding: 14px 8px !important;
  }
}

.mobile-full-width {
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

.mobile-no-padding {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.no-wrap-text {
  @media screen and (min-width: 768px){
    white-space: nowrap;
  }
}

.team-filters {
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.rdp {
  --rdp-accent-color: #0e7772 !important;
  --rdp-range_middle-background-color: rgba(14, 119, 114, 0.62) !important;
}

.rdp-day_selected.rdp-day_range_middle {
  background-color: rgba(14, 119, 114, 0.12) !important;
  color: black !important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: rgba(14, 119, 114, 0.12) !important;
  color: black !important;
}

input.form-check-input {
  &:checked {
    background-color: #13b9ae !important;
    border-color: #13b9ae !important;
    color: #fff !important;
  }
}

.react-datepicker__year-text--selected {
  color: #fff !important;
  background-color: #0e7772 !important;
}

.react-datepicker__year-text--in-range {
  color: #fff !important;
  background-color: #0e7772 !important;
}

input[type=checkbox] {
  &:checked {
    background-color: #0e7772 !important;
    border-color: #0e7772 !important;
  }
}
