.datepicker-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  min-width: 180px;
}

.arrow {
  color: #000000;
  height: 19px;
  width: 18px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 4px;
}

.datepicker-button {
  border: none;
  background: none;
  padding: 0;
}

.datepicker-header-date {
  font-size: 14px;
  font-weight: 500;
}