.container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.content {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 50%;
  background-color: #13b9ae;
  cursor: pointer;
}
