.table-skeleton {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;

  &__header,
  &__row {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
  }

  &__header {
    background: #f9fafb;
  }

  &__cell {
    flex: 1;
    padding: 0 12px;
  }

  &__content {
    height: 20px;
    background: linear-gradient(90deg, #f3f4f6 25%, #e5e7eb 50%, #f3f4f6 75%);
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
  }

  &__header-cell &__content {
    width: 70%;
  }

  &__body-cell &__content {
    width: 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
