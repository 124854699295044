// Support page styles
.ticket-list {
    margin-top: 20px;
}

.ticket-item {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #f8f9fa;
    }

    .text-end {
        min-width: 120px;
        text-align: right;
    }

    .small {
        font-size: 12px;
        opacity: 0.8;
    }
}

.ticket-item {
    cursor: pointer;
    transition: background-color 0.2s;
}

.ticket-item:hover {
    background-color: #f8f9fa;
}

.ticket-item {
  &.has-unread-messages {
    border-left: 3px solid #13b9ae !important;
    background-color: rgba(19, 185, 174, 0.03);
  }
}

// Tabs styles
.nav-pills {
    width: fit-content;
    padding: 4px !important;
    background: transparent;
    border: 1px solid #e9e9ef;
    border-radius: 36px;
    display: inline-flex;
}

.nav-item {
    margin-right: 10px;
}

.nav-item:last-child {
    margin-right: 0;
}

.nav-link {
    padding: 8px 24px;
    border-radius: 36px !important;
    color: #495057;
    font-weight: 500;
    background: transparent;
    transition: all 0.3s ease;
    text-align: center;
    border: none;
}

.nav-link:hover:not(.active) {
    background: rgba(19, 185, 174, 0.1);
}

.nav-link.active {
    background-color: #13b9ae !important;
    color: white;
    box-shadow: 0 2px 4px rgba(19, 185, 174, 0.2);
}

// Ticket page styles
.ticket-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.ticket-header {
    margin-bottom: 30px;
}

.ticket-info {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 20px;
}

.ticket-info > div {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
}

.ticket-status {
    &.open {
        background-color: #e8f5e9;
        color: #2e7d32;
    }
    &.closed {
        background-color: #eeeeee;
        color: #616161;
    }
}

.ticket-priority {
    &.low {
        background-color: #e3f2fd;
        color: #1976d2;
    }
    &.normal {
        background-color: #e8f5e9;
        color: #2e7d32;
    }
    &.high {
        background-color: #fff3e0;
        color: #f57c00;
    }
    &.urgent {
        background-color: #ffebee;
        color: #c62828;
    }
}

.ticket-category {
    background-color: #f3e5f5;
    color: #7b1fa2;
}

.ticket-dates {
    color: #757575;
    font-size: 13px;
    div {
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.support_ticket_history {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
}

.message-item {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    max-width: 80%;
}

.message-item.user {
    align-self: flex-end;
    background-color: #fff;
    margin-left: auto;
}

.message-item.support {
    align-self: flex-start;
    background-color: #f5f5f5;
    margin-right: auto;
}

.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 13px;
}

.message-author {
    color: #616161;
}

.message-time {
    color: #9e9e9e;
}

.message-content {
    color: #212121;
    line-height: 1.5;
}

.message-form {
    margin-top: 20px;
    border-radius: 8px;
}

.message-form textarea {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 15px;
    resize: vertical;
    min-height: 100px;
}

.message-form textarea:focus {
    outline: none;
    border-color: #13b9ae;
    box-shadow: 0 0 0 0.2rem rgba(19, 185, 174, 0.25);
}

.form-actions {
    display: flex;
    gap: 12px;
}

.form-actions .btn-primary {
    background-color: #13b9ae;
    border-color: #13b9ae;
    color: white;
    padding: 8px 20px;
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.2s;
}

.form-actions .btn-primary:hover {
    background-color: darken(#13b9ae, 5%);
    border-color: darken(#13b9ae, 5%);
}

.ticket-info {
    background-color: #fcfcfd;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 24px;
    border: 1px solid #edf2f7;
}

.ticket-info .text-muted {
    font-size: 0.875rem;
    margin-bottom: 4px;
    color: #64748b !important;
}

.ticket-info p:not(.text-muted) {
    font-weight: 500;
    color: #495057;
    font-size: 0.95rem;
    margin-bottom: 0;
}

.ticket-info .row > div {
    margin-bottom: 16px;
}

.ticket-info .row > div:last-child {
    margin-bottom: 0;
}

.ticket-status {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
}

.ticket-status.open {
    background-color: rgba(34, 197, 94, 0.1);
    color: #16a34a;
}

.ticket-status.closed {
    background-color: rgba(100, 116, 139, 0.1);
    color: #475569;
}

.ticket-priority {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
}

.ticket-priority.low {
    background-color: rgba(34, 197, 94, 0.1);
    color: #16a34a;
}

.ticket-priority.normal {
    background-color: rgba(59, 130, 246, 0.1);
    color: #2563eb;
}

.ticket-priority.high {
    background-color: rgba(245, 158, 11, 0.1);
    color: #d97706;
}

.ticket-priority.urgent {
    background-color: rgba(239, 68, 68, 0.1);
    color: #dc2626;
}

.ticket-category {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 500;
    background-color: rgba(99, 102, 241, 0.1);
    color: #4f46e5;
}

.ticket-form {
    margin-top: 24px;
}

.ticket-form .form-control {
    border-radius: 8px;
    border-color: #e9ecef;
    padding: 12px;
    resize: none;
}

.ticket-form .form-control:focus {
    border-color: #13b9ae;
    box-shadow: 0 0 0 0.2rem rgba(19, 185, 174, 0.25);
}

.ticket-form .btn-primary {
    margin-top: 16px;
    padding: 10px 24px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.ticket-form .btn-primary:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(19, 185, 174, 0.2);
}

.ticket-form .btn-primary:disabled {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.ticket-message {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f8f9fa;

  &.customer {
    background-color: #f0f7ff;
  }

  &.agent {
    background-color: #f8f9fa;
  }

  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;

    .message-author {
      font-weight: 600;
      color: #333;
    }

    .message-time {
      color: #666;
      font-size: 0.875rem;
    }
  }

  .message-body {
    color: #333;
    line-height: 1.5;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
      margin: 0.5rem 0;
    }
  }

  .message-attachments {
    margin-top: 1rem;
    border-top: 1px solid #e9ecef;
    padding-top: 1rem;

    .attachments-header {
      color: #666;
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;

      i {
        font-size: 1.25rem;
        margin-right: 0.5rem;
      }
    }

    .attachments-list {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
    }

    .attachment-item {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      background: white;
      border: 1px solid #e9ecef;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.2s;
      min-width: 200px;

      &:hover {
        border-color: #13b9ae;
        background-color: #f8fffe;
      }

      .attachment-icon {
        font-size: 1.5rem;
        color: #13b9ae;
        margin-right: 0.75rem;
      }

      .attachment-info {
        flex: 1;

        .attachment-name {
          font-size: 0.875rem;
          color: #333;
          margin-bottom: 0.25rem;
          word-break: break-all;
        }

        .attachment-size {
          font-size: 0.75rem;
          color: #666;
        }
      }

      .attachment-download {
        color: #13b9ae;
        font-size: 1.25rem;
        opacity: 0;
        transition: opacity 0.2s;
      }

      &:hover .attachment-download {
        opacity: 1;
      }
    }
  }
}

// Image preview styles
.image-preview-container {
  position: relative;
  padding: 1rem;
  background: #fff;
  text-align: center;
  border-radius: 8px;

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    padding: 0.25rem;
    line-height: 1;
    z-index: 1;

    &:hover {
      color: #333;
    }
  }

  .preview-image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
  }
}

.message-body {
  .inline-image {
    cursor: pointer;
    max-width: 300px;
    max-height: 300px;
    border-radius: 4px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.02);
    }
  }
}

// Modal styles
.modal {
    .modal-content {
        border-radius: 12px;
        
        .modal-header {
            padding: 1rem 1.5rem;
            border-bottom: 1px solid #e9e9ef;
            
            .modal-title {
                font-size: 1.1rem;
                font-weight: 500;
            }
        }
        
        .modal-body {
            padding: 1.5rem;
            
            .form-group {
                margin-bottom: 1rem;
                
                label {
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                    
                    &.required:after {
                        content: "*";
                        color: #f46a6a;
                        margin-left: 0.2rem;
                    }
                }
                
                .form-control {
                    border-radius: 6px;
                    border-color: #e9e9ef;
                    
                    &:focus {
                        border-color: #13b9ae;
                        box-shadow: none;
                    }
                }
            }
        }
        
        .modal-footer {
            padding: 1rem 1.5rem;
            border-top: 1px solid #e9e9ef;
        }
    }
}

// Button styles
.btn-primary-gradient {
    background: #13b9ae;
    border: none;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 30px;
    
    &:hover {
        background: darken(#13b9ae, 5%);
        transform: translateY(-1px);
    }
    
    &:active {
        transform: translateY(0);
    }
}

// Mobile styles
@media (max-width: 768px) {
    .messages-container {
        .message-item {
            max-width: 90%;
        }
    }

    .message-form {
        padding: 15px;
    }

    .ticket-info {
        gap: 8px;
        
        > div {
            padding: 4px 8px;
            font-size: 12px;
        }
    }
}

.page-content {
    padding: 20px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .header-text {
        display: flex;
        align-items: center;
        gap: 16px;

        .back-button {
            color: #000;
            padding: 0;
            font-size: 24px;
            
            &:hover {
                color: #13b9ae;
            }

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        h4 {
            margin: 0;
            font-size: 24px;
            font-weight: 500;
        }
    }

    .close-ticket-btn {
        background: #13b9ae;
        border: none;
        padding: 8px 16px;
        font-size: 14px;

        &:hover {
            background: darken(#13b9ae, 5%);
        }
    }
}

.ticket-status-section {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;

    > div {
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 14px;
    }

    .status-badge {
        &.open {
            background-color: #e8f5e9;
            color: #2e7d32;
        }
        &.closed {
            background-color: #eeeeee;
            color: #616161;
        }
    }

    .priority-badge {
        &.low {
            background-color: #e3f2fd;
            color: #1976d2;
        }
        &.normal {
            background-color: #e8f5e9;
            color: #2e7d32;
        }
        &.high {
            background-color: #fff3e0;
            color: #f57c00;
        }
        &.urgent {
            background-color: #ffebee;
            color: #c62828;
        }
    }

    .category-badge {
        background-color: #f3e5f5;
        color: #7b1fa2;
    }
}

.ticket-dates {
    color: #757575;
    font-size: 14px;
    margin-bottom: 32px;

    div {
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.ticket-history {
    h5 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
        color: #212121;
    }
}

.messages-container {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 16px;
    margin-bottom: 24px;

    .message-block {
        padding: 16px 0;
        border-bottom: 1px solid #e0e0e07d;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }

        .message-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .message-author {
                color: #616161;
                font-size: 12px;
            }

            .message-time {
                color: #9e9e9e;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .message-content {
            color: #212121;
            font-size: 14px;
            line-height: 1.5;
        }
    }
}

.message-form {
    textarea {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 12px;
        resize: vertical;
        min-height: 100px;
        margin-bottom: 16px;
        font-size: 14px;

        &:focus {
            outline: none;
            border-color: #13b9ae;
            box-shadow: 0 0 0 0.2rem rgba(19, 185, 174, 0.25);
        }

        &::placeholder {
            color: #9e9e9e;
        }
    }

    .send-button {
        background: #13b9ae;
        border: none;
        padding: 8px 24px;
        font-size: 14px;

        &:hover:not(:disabled) {
            background: darken(#13b9ae, 5%);
        }

        &:disabled {
            background: lighten(#13b9ae, 20%);
        }
    }
}

// Mobile styles
@media (max-width: 768px) {
    .page-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .close-ticket-btn {
            width: 100%;
        }
    }

    .ticket-status-section {
        flex-wrap: wrap;
    }

    .messages-container {
        padding: 12px;
    }

    .message-form {
        .send-button {
            width: 100%;
        }
    }
}

.status-tabs {
    background: #fff;
    border-radius: 8px;
    padding: 8px;

    .tabs-container {
        display: flex;
        gap: 8px;

        .tab-item {
            padding: 12px 24px;
            cursor: pointer;
            border-radius: 8px;
            font-weight: 500;
            transition: all 0.3s ease;
            color: #495057;

            &:hover {
                background: rgba(19, 185, 174, 0.1);
            }

            &.active {
                background: #13b9ae;
                color: white;
            }


            @media screen and (max-width: 768px) {
                padding: 8px 12px;
            }
        }
    }
}


.support-small-margin {
    margin-bottom: 24px;


    @media  (max-width: 768px) {
        margin-bottom: 14px !important;
    }
}

.ticket-number-title {

    @media  (max-width: 768px) {
        font-size: 18px !important;
    }
}

.ticket-details-card {
  @media  (max-width: 768px) {
      box-shadow: none !important;
  }   
}

.ticket-details-card-body {
  @media  (max-width: 768px) {
      padding: 0 !important;
  }   
}

.unread-dot {
  width: 8px;
  height: 8px;
  background-color: #ff4d4f;
  border-radius: 50%;
  display: inline-block;
  margin-left: 6px;
}

.ticket-item {
  &.has-unread-messages {
    border-left: 3px solid #13b9ae !important;
    background-color: rgba(19, 185, 174, 0.03);
  }
}

.ms-2.unread-messages-badge {
    background: #13b9ae !important;
    border: none;
    color: white;
}