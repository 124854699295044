.tabsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  overflow-x: auto;

  @media screen and (max-width: 991px) {
    gap: 0;
  }
}

.tabsItem {
  border: none;
  cursor: pointer;
  background: none;
  padding: 12px;
  width: 100%;

  &.active {
    border-radius: 3px;
    background: #13b9ae;
  }
}


.label {
  color: #495057;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;

  &.active {
    color: #fff;
  }
}
