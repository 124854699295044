.container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: auto;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 24px 10px 14px;
  background: #e5e5e5;
  height: 100%;
}

.closedContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}


.startChatBtn {
  width: 90%;
  height: 48px;
  background-color: #34C38F;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 24px auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
