.container {
    position: fixed;
    bottom: 90px;
    right: 20px;

  @media screen and (max-width: 550px) {
    bottom: 80px;
    right: 0;
  }
}

.content {
  width: 400px;
  height: min(600px, 100vh - 104px);
  background-color: white;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;

  &.fullSize {
    width: 950px;
    height: min(764px, 100vh - 104px);

    .header {
      padding: 20px 12px 20px 24px;
    }
  }


  @media screen and (max-width: 550px) {
    width: 100%;
    height: min(600px, 100vh - 70px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  background-color: #7d7d7d;
  padding: 12px 12px 12px 24px;
  border-radius: 16px 16px 0 0;
  color: #fff;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 6px;


  .action {
    cursor: pointer;
    color: #fff;
  }
}

.contentWrapper {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  overflow: hidden;
}

.initWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.modalText {
  font-size: 18px;
  text-align: center;
  margin-top: 14px;
}

.startChatBtn {
  width: 90%;
  height: 48px;
  background-color: #34C38F;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 24px auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.agentBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.agentAvatar {
  width: 32px;
  height: 32px;
  background-color: #1d5d90;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.agentStatus {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 2px solid #7d7d7d;
  position: absolute;
  display: block;
  bottom: -2px;
  right: -2px;

  &.offline, &.away {
    background-color: #faf9f9;
  }


  &.online {
    background-color: #00e164;
  }
}

.resize {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
