.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  padding: 24px 0 0 24px;
}