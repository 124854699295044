.switch-label {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  margin-bottom: 0 !important;

  --slide-distance: 1.2rem;
  --slider-size: 18px;
  --slider-padding: 0.2rem;
  --transition-duration: 200ms;

  &.sm {
    --slider-size: 13px;
  }
}

.slider {
  flex-shrink: 0;
  width: calc(
          var(--slider-size) + var(--slide-distance) + var(--slider-padding) * 2
  );
  padding: var(--slider-padding);
  border-radius: 9999px;
  background-color: #EFEFEF;
  transition: background-color var(--transition-duration);

  &::after {
    content: "";
    display: block;
    width: var(--slider-size);
    height: var(--slider-size);
    background-color: #fff;
    border-radius: 9999px;
    transition: transform var(--transition-duration);
    filter: drop-shadow(2px 1px 6px rgba(0, 0, 0, 0.25));
  }
}

.switch-label {
  &.green {
    input:checked + .slider {
      background-color: #13b9ae;
    }

    input:focus-visible + .slider {
      outline: 2px solid #13b9ae;
    }
  }

  &.blue {
    input:checked + .slider {
      background-color: #556EE6;
    }

    input:focus-visible + .slider {
      outline: 2px solid hsl(210deg, 100%, 40%);
    }
  }
}

input:checked + .slider {
  &::after {
    transform: translateX(var(--slide-distance));
  }
}

input:focus-visible + .slider {
  outline-offset: 2px;
}

.label-switch-input {
  user-select: none;
  line-height: 1.5;
  font-weight: 400;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
