.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 32px;
  height: 100%;
  padding-bottom: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 32px;
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  padding: 24px 0 0 24px;
}

.status {
  font-weight: 600;
}

.statusTitle {
  font-size: 16px;
  font-weight: 500;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    text-align: center;
  }
}

.square {
  position: relative;
  margin: 0 10px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: linear-gradient(287deg, rgba(0, 26, 255, 0.60) 7.16%, rgba(55, 171, 255, 0.60) 96.3%);
  backdrop-filter: blur(2px);
  border-radius: 32% 58% 69% 43% / 48% 32% 59% 55%;
  transition: 0.5s;
  animation: animate 6s linear infinite;
}

.square span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: linear-gradient(287deg, rgba(0, 26, 255, 0.60) 7.16%, rgba(55, 171, 255, 0.60) 96.3%);
  backdrop-filter: blur(2px);
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  transition: 0.5s;
  animation: animate 4s linear infinite;
}

.square span:nth-child(3) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: linear-gradient(287deg, rgba(0, 26, 255, 0.60) 7.16%, rgba(55, 171, 255, 0.60) 96.3%);
  backdrop-filter: blur(2px);
  border-radius: 31% 45% 74% 35% / 38% 56% 51% 87%;
  transition: 0.5s;
  animation: animate2 10s linear infinite;
}

.content {
  position: relative;
  text-align: center;
  transition: 0.5s;
  z-index: 1000;
  background: #fff;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttonContinue {
  width: 90%;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #34C38F;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: auto auto 0;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
