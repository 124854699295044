.wrapper {
  position: relative;
  margin-top: 32px;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
  padding: 17px 20px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.chart {
  width: 100%;

}

.title {
  color: #495057;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 24px;
}

.emptyState {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.legendWrapper {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    gap: 0;
    column-gap: 20px;
    justify-content: center;
  }
}

.legend {
  display: flex;
  align-items: center;
  gap: 8px;
}