.wrapper {
  background-color: white;
  padding: 16px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.block {
  display: flex;
  gap: 20px;
}

.tree {
  width: 100%;
  height: 100%;
}

.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #495057;
}

.refId {
  font-size: 14px;
  line-height: 22px;
  color: #74788D;
}

.count {
  background: #f0fdfb;
  border: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 3px 12px;
  color: #0c958f;
}

.link {
  color: #0c958f;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 170px;
  justify-content: space-between;
}

.levelInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.levelCount {
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  background: rgba(250, 203, 18, 0.50);
  color: #191A1F;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  height: 72px;
  width: 72px;
}

.photo {
  object-fit: contain;
  width: 72px;
  height: 72px;
}

.levelLabel {
  border-radius: 0px 0px 6px 6px;
  background: rgba(250, 203, 18, 0.50);
  color: #191A1F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-top: 14px;
  }
}

.treeButton {
  background-color: #f0fdfb;
  color: #0c958f;
  border: 1px solid #0c958f;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
}
