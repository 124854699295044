@import '../../assets/scss/theme.scss';

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.zero-level {
  display: grid;
  place-items: center;

  text-align: center;

  min-height: 380px;

  p {
    font-size: 15px;
    font-weight: 600;
    max-width: 340px;
  }

  .link {
    font-size: 13px;
  }
}


.dashboard-container {
  display: flex;
  background: #fff;
  border-right: 8px;
  padding: 16px 16px 16px 18px;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 768px) {
    padding: 0;
    background: none;
  }
}

.dashboard-info-block {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.dashboard-card {
  padding: 16px 24px;
  border-radius: 8px;
  border: 1.6px solid #F0F0FF;

  @media screen and (max-width: 768px) {
    border: none;
    background: #fff;
    padding: 16px;
  }
}

.dashboard-top-block {
  max-height: 500px;

  @media screen and (max-width: 1600px) {
    max-height: 375px;
  }

  @media screen and (max-width: 1240px) {
    max-height: 500px;
  }
}

.dashboard-user-info {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
  }
}

.dashboard-user-name {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;

  @media screen and (max-width: 1240px) {
    font-size: 18px;
  }
}

.dashboard_user_level_info {
  display: flex;
  align-items: center;
  gap: 6px;

  .count {
    border-radius: 50%;
    border: 2px solid #13b9ae;

    color: #13b9ae;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    @media screen and (max-width: 1240px) {
      height: 24px;
      width: 24px;
      padding: 0;
    }

  }

  p {
    padding: 0;
    margin: 0;
    color: #7676AE;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    .level-info {
      color: #13b9ae;
    }
  }
}

.dashboard-personal-goal-block {
  margin-top: 32px;


  &_title {
    color: #7676AE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 1600px) {
    margin-top: 24px;
  }

  @media screen and (max-width: 1240px) {
    margin-top: 16px;
  }
}

.dashboard-stats {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media screen and (max-width: 1600px) {
    gap: 24px;
  }
}

.user-card-info {
  width: 35%;

  @media screen and (max-width: 1240px) {
    width: 40%;
  }


  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.dashboard-notification_card {
  width: 65%;
  display: flex;
  flex-direction: column;


  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.dashboard-income-card {
  width: 70%;
  max-height: 300px;

  @media screen and (max-width: 1600px) and (min-height: 800px) {
    max-height: 240px;
  }

  @media screen and (max-width: 1600px) and (max-height: 800px) {
    width: 90%;
    max-height: 220px;
  }

  @media screen and (max-width: 1240px) {
    width: 70%;
    max-height: 300px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    max-height: unset;
  }
}

.dashboard-referrals-card {
  width: 30%;
  overflow-y: scroll;
  max-height: 300px;

  @media screen and (max-width: 1600px) and (min-height: 800px) {
    max-height: 430px;
  }

  @media screen and (max-width: 1600px) and (max-height: 800px) {
    width: 25%;
    max-height: 220px;
  }

  @media screen and (max-width: 1240px) {
    width: 30%;
    max-height: 300px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent !important;
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    width: 0 !important;
    display: none !important;
  }
}

.dashboard-block-title {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
