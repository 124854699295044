.wrapper {
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 24px;
  justify-content: space-between;
  color: #495057;
  align-items: center;

  &.show {
    margin-bottom: 0;

    @media screen and (max-width: 768px){
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
}

.showButtonWrapper {
  min-width: 220px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
      min-width: unset;
  }
}

.showButton {
  background-color: #f0fdfb;
  color: #0c958f;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  width: max-content;
  border: 1px solid #0c958f;
}

.tree {
  height: 550px;
  margin-bottom: 24px;
  width: 100%;
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.treeFilters {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 768px) {
      flex-direction: column;
    align-items: flex-start;
  }
}
