.chart__wrapper {
  display: flex;
  align-items: center;
  gap: 34px;

  @media screen and (max-width: 1600px) {
    gap: 16px;
  }

  @media screen and (max-width: 540px) {
      flex-direction: column;
  }
}

.income__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.custom-legend {
  display: flex;
  width: 100%;

  .legend-container {
    padding-right: 34px;
    border-right: 1px solid #F0F0FF;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:last-child {
      padding-right: 0;
      padding-left: 34px;
      border: none;
    }

    @media screen and (max-width: 1600px) {
      padding-right: 16px;

      &:last-child {
        padding-left: 16px;
        padding-right: 0;
      }
    }


    @media screen and (max-width: 1240px) {
      padding: 0;
      border: none;

      &:last-child {
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    flex-direction: column;
    gap: 16px;
  }
}

.legend-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.legend-item-title {
  font-size: 14px;
}

.legend-item-info {
  display: flex;
  align-items: center;
  color: #7676AE;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 128.571% */
  gap: 4px;
}

.legend-item-value {
  font-size: 14px;
}

.legend-color {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  display: block;
  flex-shrink: 0;
}

.legend-text span {
  display: block;
}

@media screen and (max-width: 1240px) {
  .income__wrapper {
    flex-direction: column;
  }

  .custom-legend {
    margin-left: 0;
    margin-top: 1rem;
    align-items: center;
  }
}


.legend-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}


.pie-container {
  display: flex;
  width: 300px;
  height: 250px;

  @media screen and (max-width: 1600px) {
    width: 200px;
    height: 170px;
  }
}
