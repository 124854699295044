.notification-setting-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
}

.notification-setting-title {
  margin: 0;
  font-size: 20px;
}

.notification-setting-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.notification-setting-head {
  margin-bottom: 12px;
}

.notification-setting-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.notification-setting-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
  align-items: center;
}

.notification-setting-type {
  font-size: 16px;
  font-weight: 500;
}
