.responsive {
  padding: 0;
  margin: 0;
}

.table_analysis_button {
  padding: 8px 12px;
  border-radius: 3px;
  background-color: #f0fdfb;
  border: 1px solid #0c958f;
}



.partnerIdWrapper {
  height: 60px;
  width: 100px;
  display: flex;
  flex-direction: column;

  .showTreeBtn {
    display: none;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 3px;
    color: #0c958f;
    font-size: 13px;
    padding: 0;

    @media screen and (max-width: 991px) {
      display: flex;
    }
  }

  @media screen and (max-width: 991px) {
    align-items: flex-end;
  }

  &:hover {
    .showTreeBtn {
        display: flex;
    }
  }
}
