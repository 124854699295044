.chartWrapper {
  width: 100%;
  height: 650px;
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.chart {
  width: 100%;
}

.legendWrapper {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    gap: 0;
    column-gap: 20px;
    justify-content: center;
  }
}

.legend {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 24px;
}

.emptyState {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chartContainer {
  width: 100%;
  height: 100%;


  @media screen and (max-width: 768px){
    width: 110%;
    margin-left: -50px;
  }

  @media screen and (max-width: 450px){
    width: 120%;
    margin-left: -50px;
  }
}