.wrapper {
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 768px) {
    padding: 24px 10px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #74788d;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 14px;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.inputs {
  display: flex;
  align-items: center;
  gap: 14px;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  min-width: 180px;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.filters {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    gap: 14px;
    width: 100%;
  }
}

.switches {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 7px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-align: left;

  @media screen and (max-width: 768px) {
    margin-top: .5rem;
    width: 100%;
  }
}

.product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  display: flex;
  align-items: center;
  gap: 4px;

  .value {
    font-weight: 600;
    white-space: nowrap;
  }
}

.searchInput {
  min-width: 180px;
  min-height: 38px;
}

.total {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 4px;

  .amount {
    font-weight: 600;
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    margin-top: 14px;
    margin-bottom: 8px;
  }
}

.saveBtn {
  cursor: pointer;
  background-color: #f0fdfb;
  color: #0c958f;
  border-radius: 8px;
  border: 1px solid #0c958f;
  padding: 6px 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }

  &.hideModal {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.dateRangePicker {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 300px;

  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: unset;
  }

  button {
    width: 100%;
  }
}

.selectDateButton {
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #ced4da;
  padding: 8px 12px;
  border-radius: 0.25rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  svg {
    margin-left: auto;
  }
}

.datePickerDropdown {
  position: absolute;
  z-index: 10;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 8px;
}
