.wrapper {
  display: flex;
  background: url("../../../assets/images/auth-bg2.png") no-repeat center center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-height: 550px) {
    height: 100%;
    padding: 50px 0;
  }

  &.registerWrapper {
    padding: 0;

    @media (max-width: 1600px) {
      .form {
        gap: 12px;
        min-width: unset;
        width: 470px;
      }

      .title {
        margin: 0;
      }

      .rememberWrapper {
        margin: 4px 0;
      }

      .bottomText {
        margin-top: 4px;
      }

      @media (max-height: 700px) {
        height: 100% !important;
        padding: 20px 0;
      }
    }

    @media (max-width: 1544px) and (min-height: 769px){
      height: 100vh;
      padding: 0;
    }

    @media  (max-width: 1024px) {
      height: 100%;
      padding: 50px 0;

      @media (min-height: 800px) and (min-width: 769px){
        padding: 0;
        height: 100vh;
      }
    }
  }
}

.formWrapper {
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 36px 46px;

  @media screen and (max-width: 1600px){
    gap: 16px;
    padding: 32px;
  }

  @media (max-width: 768px) {
    gap: 26px;
    width: 90%;
  }

  @media (max-width: 480px) {
    padding: 20px 20px 32px;
    gap: 24px;
  }
}

.logo {
  width: 105px;
  height: 30px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -1.92px;
  color: #000;
  margin-bottom: 16px;
  text-align: center;
  max-width: 542px;

  @media (max-width: 1600px) {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.72px;
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 8px;
    letter-spacing: -0.48px;
    text-align: center;
    max-width: 400px;
  }

  @media (max-width: 440px) {
    max-width: unset;
  }
}

.subtitle {
  font-family: 'Poppins', sans-serif;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  margin: 0 auto;
  max-width: 400px;

  @media screen and (max-width: 1600px){
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
  }

  @media screen and (max-width: 768px) {
    max-width: 400px;
  }

  @media (max-width: 440px) {
    max-width: unset;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 470px;

  max-width: 572px;
  gap: 24px;

  @media (max-width: 1600px) {
    gap: 16px;
  }

  @media screen and (max-width: 768px) {
    min-width: unset;
    max-width: unset;
    width: 100% !important;
  }
}

.formLabel {
  color: #333;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 12px !important;

  @media (max-width: 1600px) {
    font-size: 16px;
    line-height: 19.2px;
    margin-bottom: 8px !important;
  }
}

.actions {
  display: flex;
  width: 100%;
  justify-content: center;
}

.submitButton {
  width: 100%;
  border: none !important;
  border-radius: 6px;
  background: #13b9ae;
  display: flex;
  height: 64px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center !important;
  gap: 4px;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;

  @media (max-width: 1600px) {
    height: 48px;
    font-size: 18px;
  }

  &:hover {
    background: #0c958f;
  }
}

.forgotWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px !important;

  .formLabel {
    margin-bottom: 0 !important;
  }

  @media (max-width: 1544px) {
    margin-bottom: 6px !important;
  }
}

.forgotTxt {
  color: #0c958f;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  background: none;
  padding: 0 !important;

  &:hover {
    color: #0e7772;
  }
}

.rememberWrapper {
  display: flex;
  margin: 6px 0;
  gap: 8px;
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  align-items: center;

  @media screen and (max-width: 1600px){
    font-size: 16px;
    line-height: 19.2px;
    margin: 0;
  }

  .checkboxLabel {
    display: block;
    cursor: pointer;
    margin: 0;

    @media screen and (max-width: 768px) {
      max-width: 300px;
    }
  }
}

.bottomText {
  color: #828282;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
  text-align: center;

  .bottomTextLink {
    color: #0c958f;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.4px;

    @media screen and (max-width: 1600px){
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: 0;
    }

    &:hover {
      color: #0e7772;
    }
  }

  @media screen and (max-width: 1600px){
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.referrerName {
  color: #828282;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin-top: 8px;
  display: block;

  @media (max-width: 1600px) {
    font-size: 14px;
    margin-top: 4px;
  }
}

.registerBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.logoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.confirmCodeWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

.confirmCodeBtn {
  white-space: nowrap;
  flex-shrink: 0;
  border: none;
  //background: #b3b3b3;
  background: none;
  color: #2F80ED;
  padding: 10px 24px;
  font-size: 16px;
  height: 48px;
  border-radius: 6px;
  text-align: center;

  &:hover {
    background: #E0E0E0;
  }

  &:disabled {
    background: #E0E0E0;
    width: 130px;
  }
}

.footerLinks {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
