.wrapper {
  padding: 24px 32px;
  max-height: 600px;
  overflow-y: scroll;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close {
  cursor: pointer;
}