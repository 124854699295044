.skeleton-line {
  background: linear-gradient(90deg, #f3f4f6 25%, #e5e7eb 50%, #f3f4f6 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  animation: shimmer 1.5s infinite;
}

.notification-item-wrapper {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
  
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.notification_leftside {
  flex: 1;
  max-width: 70%;
  padding-right: 24px;
}

.notification_rightside {
  flex-shrink: 0;
  min-width: 120px;
  text-align: right;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
