.metismenu {
  padding: 0;

  li {
    display: flex;
  }
}
.li__sidebar {
  width: 220px;
}

.unread-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ff4d4f;
  border-radius: 50%;
  margin-left: 8px;
  position: relative;
  top: -2px;
}

body.vertical-collpsed {
  .metismenu {
    li {
      width: 70px;

      .li__sidebar {
        width: 70px;
      }
    }
  }

  .unread-indicator {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
