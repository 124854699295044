
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 935px;

  @media screen and (max-width: 1240px) {
    width: 100%;
    gap: 14px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 14px;
  }
}

.table {
  border-radius: 4px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  color: black;
}

.item {
  height: 100%;
  border-radius: 0 0 4px 4px;
  display: grid;
  grid-template-columns: 1fr .8fr;

  &:not(:last-child) {
    border-radius: 4px 4px 0 0;
  }

  &.fullWidth {
    grid-template-columns: 1fr;
    text-align: left;
  }
}

.label {
  padding: 8px 16px;
}

.value {
  color: #3A4045;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding: 8px 16px;
  text-align: right;
  white-space: nowrap;

  &.blue {
    color: #50A5F1;
  }

  &.green {
    color: #32d6c8;
  }
}

.textCenter {
  text-align: left;
  font-weight: 600;
  padding: 8px 16px;
}

.totalTitle {
  color: #495057;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 16px;
}

.totalValue {
  color: #32d6c8;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  padding: 8px 16px;
}

.totalBlock {
  border-radius: 4px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.currency {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #F0F2F6;
}

.currencyBlock {
  padding-right: 16px;
}
