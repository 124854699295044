.editor {
  max-width: 640px;
  min-height: 150px;
  position: relative;


  &.invalid {
    border: 1px solid red;
    border-radius: 10px;
  }
}
