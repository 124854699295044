.summary {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 24px;
  margin-bottom: 14px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.summary-item {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  font-weight: 500;
}

.summary-wrapper {
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.investments-summary-header {
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}