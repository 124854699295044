.container {
  margin: 86px 0;
  padding: 0 16px 16px;
  height: calc(100% - 86px);
  position: relative;

  @media screen and (max-width: 768px){
    padding: 0;
  }
}

.wrapper {
  background-color: #fff;
  padding: 16px 18px;
  border-radius: 8px;

  @media screen and (max-width: 1600px){
    gap: 34px;
  }

  @media screen and (max-width: 768px){
    gap: 16px;
    padding: 0;
  }
}

.promotionHeader {
  background-image: url("../../assets/images/promo.webp");
  display: flex;
  width: 100%;
  padding: 28px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  background-position: center center;
  gap: 4px;
  position: relative;
  transition: all 0.5s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 8px;
  }

  &.promotionHeaderExpanded {
    min-height: 420px;

    .promotionIcon {
      transform: translateY(-90%) translateX(65px);
      width: 90px;
      height: 90px;

      &.promotionIconExpanded {
        transform: translateY(-90%) translateX(80px);
      }
    }

    .promotionTitle {
      height: 44px;
      width: 225px;

      span {
        transform: translateX(-90px);
      }

      &.promotionTitleExpanded {
        width: 260px;
      }
    }

    .promotionRules {
      display: none;
    }

    .promotionSubtitle {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
    }
  }


  @media screen and (max-width: 1600px){
    max-height: 140px;
  }
}

.promotionTitle {
  display: flex;
  align-items: center;
  gap: 6px;
  color:  #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -1.92px;
  z-index: 2;
  transition: all 0.5s ease-in-out;


  span {
    transition: transform 0.5s ease-in-out;
  }
}

.promotionIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #F0F0FF;
  object-fit: contain;

  transition: all 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.promotionSubtitle {
  color:  #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  z-index: 2;
  transition: all 0.5s ease-in-out;
}

.promotionRules {
  color:  #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  z-index: 2;
  position: relative;
  margin-top: 4px;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: #FFF;
    transform: scale(1.05);
  }

  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    border-top: 2px solid #FFF;
    border-right: 2px solid #FFF;
    transform: rotate(45deg);
    top: 5px;
    right: -10px;
  }

  &.backBtn {
    display: block !important;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-left: 10px;

    &::after {
      transform: rotate(225deg);
      left: 0;
    }
  }
}

.backToTopBtn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #13b9ae;
  color: #13b9ae;


  svg {
    transform: rotate(90deg);
    width: 32px;
    height: 32px;
    animation: myAnim 1s ease-in 0s alternate both infinite;
  }
}

.backToTopBtnArrow {
}

@keyframes myAnim {
  0% {
    transform: scale(0.8) rotate(90deg);
  }

  100% {
    transform: scale(1) rotate(90deg);
  }
}
