.DayPicker {
    background-color: #f9f9f9;
}

.DayPicker-Caption {
    color: #333;
}

.DayPicker-NavButton {
    color: #333;
}

.DayPicker-Day {
    color: #555;
}

.DayPicker-Day--selected {
    background-color: #0e7772 !important;
    color: #fff;
}

.DayPicker-Day--disabled {
    color: #d3d3d3;
}
