.initials {
  border-radius: 50%;
  font-size: 20px;
  font-weight: 600;

  background-color: lightgrey;
  width: 100px;
  // padding-top: 100%;
  height: 100px;

  position: relative;

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}
.select__form_feedback {
  display: block !important;
}

.referrer-block {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: 1px solid #a8adbd;
  border-radius: 8px;
  width: 100%;
  padding: 8px 14px;
}
