.wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  a {
    color: #77779a;
  }
  a:hover {
    color: #2e61e6;
  }
  a:active {
    color: #2957cf;
  }
}

.currentLocation {
  color: #b6b6d0;
}

.divider {
  height: 8px;
  color: #b6b6d0;
}

.breadcrumbWrapper {
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.breadcrumbsLabel {
  margin: 0;
  cursor: default;
  user-select: none;
}