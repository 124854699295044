.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
}

.subtitle {
  color: #495057;
  font-size: 18px;
  font-weight: 500;
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 4px 0 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #677693;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 1px solid rgb(221 228 241);
  margin-bottom: 8px;
}

.amount {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.hint {
  border-radius: 4px;
  border: 1px solid #CBE4FB;
  background: #DCEDFC;
  padding: 12px 8px;
  display: flex;
  gap: 8px;
}

.amountInput {
  margin-bottom: 24px;
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.invalid {
  border: 1px solid #EC1C24 !important;
}

.warning {
  color: #EC1C24;
  font-size: 12px;
}

.amountInfo {
  font-size: 16px;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
