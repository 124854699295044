.autocompleteWrapper {
  position: relative;
  margin-bottom: 24px;
}

.autocompleteOptionsContent {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 110%;
  max-height: 200px;
  overflow-y: scroll;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;


  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 0;
  }
}

.autocompleteOptionList {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.autocompleteOption {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  gap: 8px;

  p {
    font-weight: 600;
    margin: 0;

    span {
      background: #FFFF00;
    }
  }

  &:hover {
      background-color: #E5E5E5;
  }
}

.autocompleteInputWrapper {
  border: 1px solid #ccc;
  border-radius: .25em;
  padding: 8px 12px;
}

.autocompleteInput {
  border: none;
  width: auto;
  flex: 1;
}

.tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .tag {
    border: 1px solid #ccc;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    min-width: 110px;
    justify-content: space-between;

    span {
      font-weight: 600;
    }

    button {
      background: none;
      border: none;
      padding: 0;
    }
  }
}