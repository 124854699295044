
.set-default-wallet-wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.set-default-wallet-hint {
  background-color: #DCEDFC;
  border: 1px solid #cbe4fb;
  padding: 8px 12px;
  display: flex;
  align-items: flex-start;
}

.set-default-wallet-input-block {
  margin-bottom: 14px;
}