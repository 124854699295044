.baseInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.baseInfoTitle {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
}

.userAvatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.changePhotoBtn {
  position: absolute;
  height: 30px;
  width: 72px;
  background: rgba(35, 16, 71, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  cursor: pointer;
}

.formItem {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 1240px){
      flex-direction: column;
  }
}

.formField {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input {
  height: 44px;
  font-size: 14px !important;
  line-height: 18px !important;
  border-color: #F0F0FF !important;

  &:disabled {
    background-color: #F0F0FF !important;
    color: #818181 !important;
  }
}

.blockWithBg {
  border-radius: 8px;
  background: #F0F0FF;
  padding: 12px 18px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  margin: 0;
  color: #7676AE;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;

  &.checkbox {
    cursor: pointer;
  }
}

.inviterInfo {
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    span {
      white-space: nowrap;
    }

    @media screen and (max-width: 768px){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .value {
    color: #7676AE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.walletBlock {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.changeWalletBtn {
  border: none;
  display: flex;
  width: auto;
  height: 44px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.72px;
  text-transform: uppercase;

  &.edit {
    background: #13b9ae;
  }

  &.cancel {
    background: #EB5757;
  }

  &.save {
    background: #13b9ae;
  }
}


.submitBtn {
  display: flex;
  width: 242px;
  height: 44px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: none;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.72px;
  text-transform: uppercase;
  background: #13b9ae;

  &:disabled {
    background: #A8A8D4;
  }
}

.settingBlock {
  display: flex;
  flex-direction: column;
  padding: 24px 25px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1.6px solid #F0F0FF;
  gap: 8px;

  .blockTitle {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  @media screen and (max-width: 769px){
    padding: 24px;
    background-color: #fff;
    border: none;
    border-radius: 16px;
  }
}

.passwordForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.supportLink {
  font-size: 14px;
  color: #0c958f;

  &:hover {
    text-decoration: underline;
    color: #0e7772;
  }
}
