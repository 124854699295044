.multiselectContainer {
  position: relative;
  width: 180px;
  min-height: 38px;
  flex-shrink: 0;

  @media screen and (max-width: 1240px) {
    width: 100%;
  }
}

.selectedOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  color: #74788d;
  border-radius: 0.25em;
  cursor: pointer;
  user-select: none;
  height: 38px;
}

.arrowDown {
  transform: rotate(-90deg);
}

.arrowUp {
  transform: rotate(90deg);
}

.optionsContainer {
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  z-index: 1;
  border-radius: 0.25em;
}

.optionLabel {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  margin-bottom: 0 !important;
}

.optionLabel:hover {
  background-color: #f2f2f2;
}

.placeholder {
  color: #74788d
}

.optionCheckbox {
  &:checked {
    color: #fff !important;
    accent-color: #0e7772 !important;
  }
}
