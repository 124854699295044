.container {
  display: flex;
  flex-direction: column;
  padding: 8px 24px 0;
  max-height: 60px;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 12px;
  width: 100%;
  flex-direction: column;
  gap: 4px;
}

.inputBlock {
  display: flex;
  align-items: center;
  border: 1px solid #34C38F;
  border-radius: 8px;
  height: 80px;
  width: 100%;
  position: relative;
  padding: 8px;
  transition: border-color 0.3s;
}

.dragActive {
  border-color: #00bc55;
  background-color: #e6ffed !important;
}

.input {
  flex-grow: 1;
  border: none !important;
  border-radius: 8px !important;
  resize: none;
  padding: 8px;
}

.sendBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 8px;
  &:hover {
    color: #00bc55;
  }
}

.attachBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 8px;
  &:hover {
    color: #00bc55;
  }
}

.fileNames {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.fileName {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 4px;

  .close {
    cursor: pointer;
  }
}

.modal {
  max-width: 90%;
}

.fullImage {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: contain;
}
