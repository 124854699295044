.modal {
  background-color: #f8f8fb !important;
  border-color: #f8f8fb !important;
}

.wrapper {
  padding: 14px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media screen and (max-width: 768px) {
    padding: 14px 8px;
  }
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
  padding: 24px 0 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 624px;
}

.payment {
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.modalWrapper {
  display: flex;

  @media screen and (max-width: 991px) {
    --bs-modal-width: 770px !important;
  }

  @media screen and (max-width: 768px) {
    --bs-modal-width: 90% !important;
  }
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #556EE6;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.videoModal {
  position: relative;
  padding: 1rem;
  background-color: black;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 390px;
  width: 100%;

  @media (max-width: 768px) {
    height: 200px;
  }
}

.closeButton {
  cursor: pointer;
  z-index: 1000;
  top: -12px;
  right: -12px;
  width: 25px;
  height: 25px;
  font-size: 20px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
    font-size: 14px;
    top: -10px;
    right: -10px;
  }
}

.videoInstruction {
  cursor: pointer;
  color: #0c958f;
  text-decoration: underline;
}
