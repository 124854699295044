.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: rgba(231,231,250,0.2);
  }

  &.active {
    border-right: 5px solid #34C38F;
    background-color: rgba(231,231,250,0.3);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: rgba(231,231,250,0.2);

    &:hover {
        border: none;
    }
  }

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 14px;
  padding: 24px 0 0 24px;
}


.buttonContinue {
  width: 90%;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #13b9ae;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: auto auto 0;

  &.disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: #0c958f;
  }
}

.amountInput {
  margin-bottom: 24px;
  padding: 24px;
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.invalid {
  border: 1px solid #EC1C24 !important;
}

.warning {
  color: #EC1C24;
  font-size: 12px;
}

.amountInfo {
  font-size: 18px;
}

.amountHint {
  font-size: 18px;
  padding: 24px;
  text-align: center;
}
