.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0;
  text-align: center;
}

.amountInfo {
  font-size: 16px;
  flex-direction: column;
  text-align: center;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.amount {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 22px;
}

.contentWrapper {
  display: flex;
}

.paymentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px;
  align-items: center;
}

.qrWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  padding: 24px;
}

.description {
  text-align: center;
}

.address {
  border-radius: 4px;
  border: 1px solid #CED4DA;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 8px 10px;
  }
}

.copyButton {
  border-radius: 4px;
  background: #13b9ae;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border: none;
  color: #FFFFFF;

  &:hover {
    cursor: pointer;
    background: #0c958f;
  }
}

.timerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer {
  color: #495057;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.buttonContinue {
  width: 90%;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #13b9ae;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: auto auto 0;

  &:hover {
    background-color: #0c958f;
  }
}

.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  &.description {
    height: 39px;
    width: 100%;
  }

  &.qr {
    width: 200px;
    height: 200px;
  }

  &.hint {
    height: 20px;
    width: 180px;
  }

  &.address {
    height: 45px;
    width: 100%;
    border: none;
  }

  &.timer {
    width: 160px;
    height: 51px;
    margin-top: 24px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.walletsTabs {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  padding: 0 0 0 14px;
  border-right: 3px solid rgba(0, 0, 0, .2);
  margin-top: 10px;
  max-width: 60px;
}


.walletItem {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 14px;
  padding-right: 10px;
  max-width: 60px;

  &.active {
    border-right: 3px solid #13b9ae;
    padding-right: 10px;
    margin-right: -3px;
  }
}

.paymentButton {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  background-color: #13b9ae;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;

  &:hover {
    background-color: #0c958f;
  }
}


.paymentButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.hint {
  color: #EC1C24;
  position: absolute;
  bottom: -20px;
}


.expiredWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(3px);
  transition: 0.3s ease;
}

.expiredBage {
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 600;
  color: #32394e;
  margin: 110px 0;
  padding: 12px;
  border: 3px solid;
  border-radius: 26px;
  rotate: -15deg;
  background: #bfc8e2;
}

.expiredText {
  font-size: 24px;
  text-align: center;
  color: #32394e;
  font-weight: 600;
  max-width: 410px;
  background: #ffffff;
  margin-top: 50px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.qrError {
  background-color: #fff;
  padding: 14px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  text-align: center;
  max-width: 80%;
}

.supportLink {
  text-decoration: none;
  color: #fff;
  background-color: #13b9ae;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 200px;

  &:hover {
    color: #fff;
  }
}
