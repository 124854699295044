.progress {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0px;
}

.progress_header,
.progress_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress_bar {
  flex: 1 1 auto;
  width: 100%;
  background: #d2d2d2;
  height: 7px;
  border-radius: 20px;
}

.progress_bar_completed {
  background-color: #1890ff;
  height: 100%;
  max-width: 100%;
  border-radius: 20px;
}
