.metrics__platform_data_wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
}

.metrics__platform_data_item_content {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #495057;
  margin: 0px 4px;
  margin-top: 10px;
  img {
    margin-right: 5px;
  }
}
.metrics__platform_data_title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  color: #495057;
}

// Icon nav
.icon-tab .nav-link {
  border: 1px solid #e9e9e9;
  color: #495057;
}
.icon-tab .active.nav-link {
  background: #556ee6;
  color: #fff;
}

.metrics__table_title {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #495057;
}

.icon-tab .nav-link i,
.vertical-icon .nav-link i {
  padding-right: 3px;
  font-size: 14px;
}
.vertical-icon .active.nav-link {
  border: 1px solid #556ee6;
  border-radius: 21px;
  background: #556ee6;
  color: #fff;
}
.vertical-icon .nav-link {
  color: #495057;
}

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-700;
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-700;
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  padding-bottom: 20px;
  justify-content: end;

  .nav-item {
    position: relative;
    color: $dark;
    .nav-link {
      border: none;

      &::after {
        content: '';
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.th__pointer {
  th {
    cursor: pointer;
  }
}

.metrics-custom-tabs {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 4px;
  border: none;

  .nav-item {
    margin: 0 4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .nav-link {
      padding: 8px 24px;
      color: #495057;
      font-weight: 500;
      background: transparent;
      border: none;
      border-radius: 4px;
      transition: all 0.2s ease;

      &.active {
        background: #13b9ae;
        color: white;
        box-shadow: 0 2px 4px rgba(19, 185, 174, 0.2);
      }

      &:hover:not(.active) {
        background: rgba(19, 185, 174, 0.1);
      }
    }
  }
}

@media (max-width: 655px) {
  .flex-wrap-mobile {
    flex-wrap: wrap;
    .metrics__table_title {
      margin-bottom: 10px;
    }
  }
  .metrics__platform_data_wrapper {
    flex-direction: column;
    gap: 10px;
  }
}

.metrics__table_title_wrapper {
  align-items: center;
  margin-bottom: 24px;
}