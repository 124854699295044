.empty-state-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.empty-state-with-bg {
  background-color: #f1f5fa;
  padding: 100px 0;
  margin-bottom: 24px;
  //margin-top: -24px;
}
