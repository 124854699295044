.auth-input {
  font-family: Inter, sans-serif;
  display: flex;
  height: 58px;
  font-size: 18px !important;
  padding: 10px 8px 10px 13px;
  border-radius: 6px !important;
  border: 1.6px solid #BDBDBD !important;
  background: #FFF;
  color: #000 !important;

  &::placeholder {
    color: #828282;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &.password-input {
    padding-right: 64px;

    &.is-invalid {
      border-color: #EB5757 !important;
    }

    &.sm {
      border-color: #F0F0FF !important;
      height: 44px !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;

      &.is-invalid {
        border-color: #EB5757 !important;
      }
    }

    .lg {
      height: 58px;
      font-size: 18px !important;

      &.is-invalid {
        border-color: #EB5757 !important;
      }
    }
  }

  @media (max-width: 1600px) {
    height: 48px;
    padding: 13px 4px 13px 16px;
    font-size: 16px !important;
    line-height: 19px;
  }
}


.password-input-show-btn {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  background: #F2F2F2;
  cursor: pointer;
  z-index: 9 !important;
  border: none !important;
  position: absolute !important;
  right: 6px;
  top: 6px;

  &:hover {
    background: #E2E2E2;
  }

  @media (max-width: 1600px) {
    width: 40px;
    height: 40px;
    right: 4px;
    top: 4px;
  }

  &.sm {
    width: 36px !important;
    height: 36px !important;
    background: #F0F0FF;

    right: 6px !important;
    top: 4px !important;
  }
}

.auth-input-warning-hint {
  color: #EB5757;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.react-select__control {
  height: 58px !important;
  border-radius: 6px !important;
  border: 1.6px solid #BDBDBD !important;

  @media (max-width: 1600px) {
    height: 48px !important;
  }
}

.react-select-profile__control {
  height: 44px !important;
  border-radius: 6px !important;
  border: 1.6px solid #F0F0FF !important;
}

.react-select__menu {
  z-index: 100 !important;
}

.react-select__value-container {
  color: #000 !important;
  font-size: 18px !important;
  padding-left: 14px !important;

  @media (max-width: 1600px) {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
}

.react-select-profile__value-container {
  color: #000 !important;
  padding-left: 14px !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}


.phone {
  height: 58px !important;
  border-radius: 6px !important;
  border: 1.6px solid #BDBDBD !important;

  @media (max-width: 1600px) {
    height: 48px !important;
  }

  &.phoneInvalid {
    border: 1.6px solid #EB5757 !important;
  }

  &.profile-phone {
    border-color: #F0F0FF !important;
    height: 44px !important;
  }
}

.phoneInput {
  height: 100% !important;
  color: #000 !important;
  font-size: 18px !important;
  padding-left: 68px !important;
  border: none !important;

  @media (max-width: 1600px) {
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding-left: 58px !important;
  }

  &.profile-phone-input {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.phoneBtn {
  border: none !important;
  display: flex;
  width: 48px;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px !important;
  background: #F2F2F2 !important;
  cursor: pointer;
  z-index: 10 !important;
  position: absolute !important;
  left: 6px !important;
  top: 4px !important;

  @media (max-width: 1600px) {
    width: 40px !important;
    height: 40px !important;
    left: 4px !important;
    top: 3px !important;
  }

  &:hover {
    background: #E2E2E2 !important;
  }

  &.open {
    background: #E2E2E2 !important;
  }

  &.profile-phone-btn {
    width: 36px !important;
    height: 36px !important;
    top: 2px !important;

    .selected-flag {
      width: 36px !important;
      padding: 0 0 0 10px !important;
      border-radius: 3px !important;

      .flag {
        .arrow {
          display: none !important;
        }
      }
    }
  }
}


.phoneDropdown {
  bottom: -220px !important;
  left: 0;
  z-index: 15 !important;
  width: 520px !important;

  @media (max-width: 768px) {
    width: 420px !important;
  }

  @media (max-width: 480px) {
    width: 280px !important;
  }

  &.phoneDropdownProfile {
    width: 340px !important;
  }
}
