.team-info {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.same-height {
  height: calc(100% - 24px);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__name {
    font-size: 13px;
  }

  &__value {
    font-size: clamp(10px, 1vw, 20px);
    font-weight: 500;
  }

  p {
    padding: 0;
    margin: 0;
  }

  &__with-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    .data-input {
      visibility: hidden;
      height: auto;
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
}

.partners-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.team_header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;

  button {
    background-color: #13b9ae;
    border-radius: 3px;
    color: #fff;
    border: none;
    padding: 8px 12px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #0c958f;
    }
  }
}
