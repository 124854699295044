//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: $display-block;
  }
  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type='input'] + .btn .mdi-eye-outline {
    &:before {
      content: '\F06D1';
    }
  }
}

// authentication full page

.auth-full-bg {
  background-color: rgba($primary, 0.25);
  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background: url('../../../images/bg-auth-overlay.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.auth-full-page-content {
  display: flex;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}

.wrap-auth-preloader {
  width: 100vw;
  height: 100vh;
  //perspective: 1000;
  backface-visibility: hidden;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-auth-preloader {
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(98, 90, 212, 0.5);
  animation: pulse 1.5s infinite;
}

.logo-auth-preloader {
  width: 100px;
  height: 60px;
}

.auth-link-resend {
  color: #2F80ED;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;
  border: none;
  background: none;
  margin-bottom: 14px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
  }
}

.auth-verify-code {
  height: 50px;
  max-width: 210px;
  font-size: 44px;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.users_change_wallet_wrapper {
  min-width: 650px;

  @media (max-width: 768px) {
    min-width: 90%;
  }
}

.users_change_wallet_title {
  color: #000;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 112.5% */
  letter-spacing: -1.92px;

  @media (max-width: 1500px) {
    font-size: 34px;
    line-height: 44px;
    letter-spacing: -0.92px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0;
  }
}

.users_change_wallet_address {
  color: #2F80ED;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: 1500px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.users_change_wallet_text {
  color: #000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media (max-width: 1500px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.users_change_wallet_actions {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 40px auto 0;

  @media (max-width: 1500px) {
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    gap: 16px;
  }
}

.users_change_wallet_action {
  width: 100%;
  height: 50px;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  letter-spacing: -0.4px;

  @media (max-width: 1500px) {
    height: 50px;
    font-size: 18px;
    letter-spacing: 0;
  }

  @media (max-width: 768px) {
    height: 40px;
    font-size: 16px;
  }
}

.users_change_wallet_btn {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background: #27AE60;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
  border: none;

  @media (max-width: 1500px) {
    height: 50px;
    font-size: 18px;
    letter-spacing: 0;
  }

  @media (max-width: 768px) {
    height: 40px;
    font-size: 16px;
  }

  &:disabled {
    background: #256b41;
    opacity: .7;
  }
}


.edit-photo-title {
  color: #000;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  width: 100%;
  margin: 0;
}

.edit-photo-btn {
  border: none;
  display: flex;
  height: 40px;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  white-space: nowrap;

  &.cancel {
    background: #EB5757;
  }

  &.save {
    background: #27AE60;
  }
}
