// Breadcrumb item arrow
.breadcrumb-item {
  &.active {
    > a {
      color: #74788d;
    }
  }
  > a {
    color: #495057;
  }
  + .breadcrumb-item {
    &::before {
      font-family: 'Material Design Icons';
    }
  }
}
