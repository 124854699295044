.wrapper {
  position: absolute;
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 8px;
  border: 5px solid #EC1C24;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px 12px;
}


.title {
  color: #EC1C24;
  font-size: 26px;
  font-weight: 600;
}

.hint {
  color: #495057;
  font-size: 24px;
  font-weight: 600;
}

.description {
  color: #495057;
  font-size: 18px;
  font-weight: 500;
}


.confirmBtn {
  margin-top: 24px;
  width: 90%;
  border: none;
  border-radius: 4px;
  background-color: #34C38F;
  color: #fff;
  padding: 12px 0;

  &:disabled {
    cursor: not-allowed;
    background-color: #808080;
  }
}
