.title {
  display: flex;
  justify-content: space-between;

  &__count {
    color: #74788d;
  }
}
.notification_card {
  padding: 0.55rem 0.55rem;
  border-bottom: 1px rgba(73, 80, 87, 0.6);
  display: flex;
}
.notification_card p {
  margin-bottom: 0;
}
.notification_leftside {
  width: 75%;
}
.notification_rightside {
  width: 30%;
}

.notification_title {
  color: #231047;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 3px !important;
}

.notification_text {
  color: #48486b;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.notification_type {
  color: #7676AE;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.notification_date_list {
  color: #A8A8D4;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
}

.notification_read_more {
  font-size: 13px;
  color: rgba(24, 144, 255, 1);
  text-align: right;
}
.notification_date {
  font-size: 13px;
  color: rgba(116, 120, 141, 1);
  text-align: left;
}
.notification_bottom {
  float: left;
  width: 100%;
}
.notification_bottom p {
  float: left;
  width: 49.5%;
}

.same-height {
  height: calc(100% - 24px);
}

.referrals-zero {
  display: grid;
  place-items: center;
  font-size: 15px;
  font-weight: 500;
  height: 100%;
  text-align: center;

  .link {
    font-size: 13px;
  }
}

.referrals {
  display: flex;
  justify-content: space-between;
}

.small-level {
  width: 20px;
  height: 20px;
  border: 1px solid #556ee6;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #556ee6;
  font-size: 13px;
}

.active-count {
  color: #4dcb73;
}

.inactive {
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
  color: rgba(73, 80, 87, 0.6);
}

.level-typography {
  display: flex;
  gap: 10px;
}

.border-top {
  border-top: 1px solid #eff2f7;
  padding: 10px 0;
}


.notification_title_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.notification_title_block_text {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins,sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.notification-view-all {
  color: #0c958f;
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &:hover {
      color: #0e7772;
  }
}

.notification-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #E0E0E0;

  p {
    margin: 0;
    padding: 0;
  }

  h4 {
    margin: 0;
    padding: 0;
  }

  a {
    color: #9B51E0;

    &:hover {
      color: #9B51E0;
    }
  }
}

.notification-list-wrapper {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    width: 0 !important;
    background: transparent !important;
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    width: 0 !important;
    display: none !important;
  }
}
