.statsContainer {
  display: flex;
  gap: 34px;
  margin-top: 20px;

  @media (max-width: 1600px) {
    margin-top: 16px;
  }

  @media (max-width: 1200px) {
    gap: 24px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 24px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 1600px) {
    width: 65%;

    &:last-child {
      width: 35%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &:last-child {
      width: 100%;
    }
  }
}

.userLevel {
  color: #231047;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 32px;

  span {
    color: #13b9ae;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 72px;
    border: 2px solid #13b9ae;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 6px 12px 5px 11px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1600px) {
    margin-bottom: 20px;
  }
}

.statsBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-bottom: 32px;

  &:last-child {
    padding-bottom: 0;
    padding-top: 32px;
    border-top: 1px solid #E0E0E0;
  }

  @media screen and (max-width: 1600px) {
    padding-bottom: 20px;

    &:last-child {
      padding-top: 20px;
    }
  }
}

.blockLabel {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.goalChecker {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.goalItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.goalItemLabel {
  color: #7676AE;

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  span {
    color: #000;
  }
}

.goalLineContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.goalLineWrapper {
  width: 100%;
  border-radius: 6px;
  height: 6px;
  background: #F0F0FF;
  margin-top: 8px;
  position: relative;
}

.goalLine {
  border-radius: 6px;
  background: #13b9ae;
  height: 6px;
  width: 0;
}

.goalValueBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;

  div {
    width: 100%;

    &:last-child {
      margin-right: 30px;
    }
  }

  &:last-child {
    div {
      text-align: right;
      margin-right: 0;

      &:first-child {
        margin-left: 30px;
      }
    }
  }
}

.promoLabel {
  color: #13b9ae;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 200% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 480px){
    transform: translateY(-10px);
  }
}

.interimStatsBlock {
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: center;

  .pointer {
    border-radius: 6px;
    background: #13b9ae;
    height: 16px;
    width: 4px;
  }
}

.secondRatingList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 32px;

  @media screen and (max-width: 1600px) {
    gap: 16px;
    margin-top: 24px;
  }
}

.ratingListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 4px;
  color: #231047;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.userAmount {
  color: #219653;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &.lg {
    font-size: 14px;
  }
}

.faded {
  opacity: 0.5;
}

.leaderBoardTable {
  border-radius: 8px;
  border: 1.6px solid #F0F0FF;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;

  &:last-child {
    margin-top: 16px;
  }
}

.leaderBoardTableTitle {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.viewAll {
  background: none;
  border: none;
  color: #13b9ae;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.leaderBoardModalContent {
  max-height: 450px;
  overflow-y: scroll;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ratingListItem {
    font-size: 14px;
  }
}


.rulesContainer {
  width: 834px;
  margin: 32px auto 0;
  padding-bottom: 78px;

  @media screen and (max-width: 1600px) {
    width: 608px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 27px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
}

.rulesTitle {
  color: #231047;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.48px;
  margin-bottom: 24px;
}

.rulesText {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.rulesHint {
  border-radius: 8px;
  background: #F0F0FF;
  display: flex;
  padding: 12px 18px 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 32px;
}

.rulesHintTile {
  color: #231047;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
}

.rulesHintText {
  color: #231047;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.rulesDescription {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
}

.rulesDescriptionTitle {
  color: #231047;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;

  span {
    color: #13b9ae;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 50%;
    border: 2px solid #13b9ae;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}

.rulesDescriptionTable {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1.6px solid #F0F0FF;
  margin-top: 16px;
}

.rulesDescriptionTableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerLabel {
  max-width: 180px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins, sans-serif;

  &.grey {
    font-weight: 400;
    color: #7676AE;
    font-family: Inter, sans-serif;
  }
}

.count {
  border-radius: 50%;
  background: #F0F0FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7676AE;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.rulesLevelItem {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #231047;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.rulesDescriptionItem {
  padding: 8px 0;
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rulesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-content: end;
}

.selfEnd {
  justify-self: end;
}

.selfCenter {
  justify-self: center;
}

.tableDescription {
  color: #7676AE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 16px;
}


.exampleItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid #E0E0E0;

  &.borderLess {
    border: none;
    padding: 12px 0;
  }
}

.superPromoTitle {
  color: #231047;
  font-family: Poppins, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 111.111% */
  letter-spacing: -0.72px;
  margin-top: 48px;
}

.superPromoDesc {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
}

.superPromoDescList {
  list-style-type: '- ';
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}


.emptyStats {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 160px;
  justify-content: center;

  p {
    margin-bottom: 0;
    margin-top: 8px;
    padding: 0;
    color: #7676AE;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}


.closeBtn {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
}

.superPromoImg {
  width: 100%;
}

.superPromoLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: max-content !important;
  margin-left: 0 !important;
}
