.level {
  width: 110%;
  aspect-ratio: 1;
  border: 2px solid #556ee6;
  border-radius: 50%;
  align-items: center;
  max-width: 52px;
  position: relative;
  box-sizing: border-box;
  justify-self: flex-end;

  @media (max-width: 600px) {
    justify-self: unset;
    width: 60px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(20px, 2vw, 40px);
    font-weight: 500;
    color: #556ee6;
    padding: 0;
    margin: 0;
    line-height: 0;

    @media (max-width: 1200px) {
      font-size: 40px;
    }
  }
}

.table {
  display: table;
}

.no-wrap {
  white-space: nowrap;
}

.typography {
  p {
    padding: 0;
    margin: 0;

    font-size: clamp(8px, 0.75vw, 13px);

    @media (max-width: 1200px) {
      font-size: 13px;
    }
  }

  h5 {
    font-size: clamp(13px, 1vw, 16px);

    @media (max-width: 1200px) {
      font-size: 15px;
    }
  }

  &__with-icon {
    display: flex;
  }
}

.level-container {
  display: flex;
  // grid-template-columns: 1fr 3fr;
  gap: 20px;
  align-items: flex-start;
  display: flex;

  @media (max-width: 600px) {
    // grid-template-columns: 1fr;
    margin-top: 20px;

    font-size: 20px;
    justify-items: center;
  }
}
.dashboard__to_next_title {
  font-size: 1.05rem;
}

.dashbaord__level_title {
  text-align: left;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 2px;
}

.dashboard__to_next_level {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;


  .dashboard__level_amount {
    color: #7676AE;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 2px;

    span {
        color: #000;
    }
  }

  .level__line {
    height: 7px;
    flex: 1 1 auto;
    width: 100%;
    border-radius: 20px;
    display: flex;
    background: #F0F0FF;
    margin-bottom: 8px;

    div {
      border-radius: 20px;
    }
    .level__line_first {
      background-color: #13b9ae;
    }
    .level__line_second {
      background-color: #13b9ae;
    }
  }
}

.level__line_row {
  display: flex;
}

.copy__link {
  color: #231047;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

@media screen and (max-width: 1320px) {
  .level__line {
    width: 100% !important;
    max-width: none !important;
  }
}

.dashboard-ref-link {
  display: flex;
  align-items: center;
  margin-top: 32px;
  border-radius: 8px;
  background: #f0fdfb;
  padding: 10px 12px;
  gap: 4px;

  @media screen and (max-width: 1600px) {
    margin-top: 24px;
  }
}

.dashboard_user_level_info {
  // .verification-badge {
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   background-color: #13b9ae;
  //   color: white;
  //   padding: 0px 6px;
  //   border-radius: 4px;
  //   font-size: 12px;
  //   width: fit-content;
  // }

  .level-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      margin: 0;
    }
  }

  .verification-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(19, 185, 174, 0.14);
    color: #13b9ae;
    padding: 0px 6px;
    border-radius: 4px;
    font-size: 12px;
    width: fit-content;
  }
}
