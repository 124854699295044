.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  height: 100%;
  min-height: 100%;
}


.title {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 32px;
}

.text {
  font-size: 20px;
  text-align: center;
  margin-top: 14px;
}
