.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.title {
  color: #495057;
  font-size: 20px;
  font-weight: 600;
  padding: 24px 0 0 24px;
}


.buttonContinue {
  width: 90%;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #34C38F;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 32px auto 0;
}
