.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  padding: 20px;
  display: flex;
  width: 100%;
  position: relative;
}

.body {
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  background-color: #EFF2F7;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
}

.refInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 32px;
  row-gap: 8px;
  width: 100%;

  .text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;

    &.bold {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 110px;
}

.chartWrapper {
  position: relative;
  height: 360px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #EFF2F7;
  background: #FFF;
  padding: 20px 20px 12px 20px;


  @media screen and (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    padding: 14px 12px 12px 0;
  }
}

.chart {
  width: 100%;
}

.tableWrapper {
  width: 100%;

  &.paddingNull {
    padding: 0;
  }

  @media screen and (max-width: 991px) {
    overflow-x: scroll;
  }
}

.table {
  border-radius: 3px;
  border: 1px solid #EFF2F7;
  background: #FFF;

  .row {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid #EFF2F7;

    &.long {
      width: 100%;
      grid-template-columns: .5fr 1fr 1fr 1fr .5fr;

      @media screen and (max-width: 991px){
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &.fundsRow {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;

    &.lng {
      width: 1100px;
    }

    .circle {
      display: none;
    }
  }

  &.byTypeTable {
    @media screen and (max-width: 991px){
      width: 900px;
    }

    @media screen and (max-width: 768px){
      width: 630px;
    }

    @media screen and (max-width: 576px){
      width: 500px;
    }
  }
}

.date {
  padding: 12px;
  border-right: 1px solid #EFF2F7;

  &:last-child {
    border-right: none;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;

  &.blue {
    background-color: #556EE6;
  }

  &.yellow {
    background-color: #F1B44C;
  }

  &.pink {
    background-color: #EB2F96;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px;
  border-right: 1px solid #EFF2F7;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  height: 100%;

  &.bold {
    font-weight: 600;
  }

  &.highlighting {
    background-color: #F0F6FC;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px 24px;
}

.closeWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 4px;
  right: 4px;
}

.close {
  cursor: pointer;
}

.legendWrapper {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 0;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.colorDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;

  @media screen and (max-width: 768px){
    width: 8px;
    height: 8px;
  }
}

.legend {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 768px){
    font-size: 12px;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #EFF2F7;
  border-radius: 3px;
  padding: 16px;
  gap: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-height: 70%;
  background: #FFF;
  overflow: auto;
  z-index: 10;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  border-radius: 8px;
}

.personalDetails {
  position: relative;

  &:hover {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }

    cursor: pointer;
  }
}

.dates {
  display: flex;
  gap: 4px;
  font-weight: 600;
}

.detailsTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
}

.filters {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }
}

.textButton {
  color: #556EE6;
  cursor: pointer;
  border: none;
  background: none;
}

.pickerContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: 100%;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 10;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #556EE6;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dateRangePicker {
  display: flex;
  align-items: center;
  gap: 6px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
  }
}

.datePickerWrapper {
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.datePickerDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  padding: 10px;
}

.selectDateButton {
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #ced4da;
  padding: 8px 12px;
  border-radius: 0.25rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.filterItem {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.selectGroupType {
  padding: 8px 32px 8px 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.summary {
  background-color: #13b9ae2e;
  height: 100%;
}

.divider {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.label {
  text-align: center;
}
