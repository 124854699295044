.container {
  margin: 86px 0;
  padding: 0 16px 16px;
  height: calc(100% - 86px);

  @media screen and (max-width: 768px){
    padding: 0;
  }
}

.wrapper {
  background-color: #fff;
  padding: 16px 18px;
  border-radius: 8px;
  display: flex;
  gap: 40px;

  @media screen and (max-width: 1600px){
    gap: 34px;
  }

  @media screen and (max-width: 768px){
    flex-direction: column;
    gap: 16px;
    background-color: unset;
    padding: 0;
  }
}

.settingBlockWrapper {
  width: 100%;

  @media screen and (max-width: 768px){
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
  }
}

.settingGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

