.message {
  display: flex;
  margin: 10px 0;

  &.agent {
    justify-content: flex-start;
  }

  &.user {
    justify-content: flex-end;
  }
}

.content {
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  max-width: 80%;
  word-wrap: break-word;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &:hover .replyBtn {
    display: flex;
  }
}

.image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
  cursor: pointer;
}

.modal {
  max-width: 90%;

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fullImage {
  width: 100%;
  height: auto;
}

.replyBtn {
  width: 20px;
  border: 1px solid #666666;
  height: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  top: 2px;
  right: 2px;
  display: none;

  &:hover {
    background-color: #666666;
    color: #fff;
  }
}

.attachment {
  .imageContainer {
    max-width: 100%;
    max-height: 200px;

    img {
      max-width: 100%;
      max-height: 200px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  p {
    margin: 5px 0;
  }
}

.fileContainer {
  margin-top: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileLink {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.reply {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 1.4;

  .originalMessage {
    font-size: 12px;
    color: #555;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .replyMessage {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

strong {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
